@import 'libs/theme/admin_scss/global_variables';
@import 'libs/theme/admin_scss/global_mixin';

frontend-custom-select {
  .creatable-select {
    .ng-select {
      display: flex;
      align-items: center;
      max-width: 100%;
      background: $brand-white;
      height: 48px;
      padding: 10px 30px 10px 10px;
      border: 1px solid $neutral_20;
      border-radius: 16px;

      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          background-color: $brand-white;

          .ng-option {
            color: $text-black;

            &:hover {
              background-color: $white_100;
            }
          }
        }
      }

      .ng-value-label {
        color: $text-black !important;
      }

      &.ng-select-opened {
        border-radius: 16px 16px 0 0;
      }
    }

  }
}

