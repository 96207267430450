
 body{
     background-color: #fff !important;
 }
 $fonfamily: 'Poppins', sans-serif;

 /*====================
 1.Brand Primary Variables
 ====================*/

 $brand-primary: #255a61;
 $brand-white: #fff;
 $brand-gray: #9faeb4;
 $banner-gray: #cfd6d9;
 $gray-50: #d1d6dc;
 $white_100: #FEF6F0;
 $white_light-gray: #F9FBFC;
 $brand-dark: #0f3443;
 $brand-color: #0e4455;
 $brand-black: #000;
 $brand-transparent: transparent;
 $brand-light-green: #255A61;
 $brand-green-dark: #173d42;
 $fields-color: #6f858e;
 $nav-color: #09738e;
 $primary_dark: #173D42;
 $primary_90: #2D3136;
 $primary_80: #5C6B74;
 $primary_60: #73848D;
 $primary_40: #8B9DA7;
 $primary_20: #A4B6C0;
 $primary_10: #BFD0DA;
 $neutral_40: #A0A7AF;
 $neutral_80: #727981;
 $neutral_60: #929196;
 $neutral_20: #D1D6DC;
 $neutral_10: #F8F8F8;
 $text-black: #000;
 $blue_text_color: #00d1ff;
 $green-light: #5dedb1;
 $bg-dark-blue: #274855;
 $orange-switch: #ECA264;
 $bg-white-disable: #f9fbfc;
 $gray_200: #a0a7af;
 $gray_300: #8b9da7;

 $btn_bg: linear-gradient(135deg, #34e89e 0%, #2e85a7 100%);
 $heading_font: 64px;
 $subheading: 24px;
 $subheading1: 32px;
 $pera: 14px;
 $fs-16: 16px;
 $fs-10: 10px;
 $btnbg: url(../assets/images/background.png);
 $background: url(../assets/images/hero.png);
 $check: url(../assets/images/Check.svg);
 $live_show: url(../assets/images/live_show.png);
 $body_bg: url(../assets/images/Landing_page.png);
 $section_bg: url(../assets/images/Slice-1.png);
 $header_boy: url(../assets/images/header_boy.png);
 $ask_question: url(../assets/images/ask_question.png);
 $play_icon: url(../assets/images/play.svg);
 $photo: url(../assets/images/Photo.png);
 $faq_bg: url(../assets/images/faq_bg.png);
 $footer_bg: url(../assets/images/footer_bg.png);
 $vector: url(../assets/images/Vector-2.png);
 $btnbg: url(../assets/images/background.png);
 $mobile_view: url(../assets/images/mobile_view.png);
 $Plus_Icon: url(../assets/images/plus_Icon.svg);
 $minus_Icon: url(../assets/images/minus_Icon.svg);
 $Plan_footer: url(../assets/images/plan_footer.png);
 $shape_bottom: url(../assets/images/shape_bottom.png);
 $shape_top: url(../assets/images/shape_top.png);
 $vectordown: url(../assets/images/Vector.png);
 $artist_bg: url(../assets/images/artist_bg.png);
 $union_bg: url(../assets/images/Union.png);
 $circle_bg: url(../assets/images/circle.png);
 $check_bg: url(../assets/images/check.png);
 $artist_footer_bg: url(../assets/images/artist_footer_bg.png);
 $live__show: url(../assets/images/live__show.png);
 $setting_bg: url(../assets/images/setting_bg.png);
 $request_bg: url(../assets/images/request_bg.png);
 $checked_bg: url(../assets/images/checked.png);
 /*====================
 1.responsive
 ====================*/

 $fs-45: 45px;
 $fs-32: 32px;
 $fs-28: 28px;
 $fs-26: 26px;
 $fs-24: 24px;
 $fs-22: 22px;
 $fs-20: 20px;
 $fs-18: 18px;
 $fs-16: 16px;
 $fs-14: 14px;

 // spacing

 $mt-16: 16px;
