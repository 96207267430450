@import '~bootstrap/scss/bootstrap';
@import '~datatables.net-dt/css/jquery.dataTables.min.css';
@import '~intl-tel-input/build/css/intlTelInput.min.css';
@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import 'node_modules/ngx-toastr/toastr';
@import 'libs/theme/admin_scss/global_variables';
@import 'libs/theme/admin_scss/global_mixin';
body {
  font-family: $fonfamily;

  a {
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

button:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}
::-webkit-scrollbar {
  display: none;
}

/*====================
Header css start
====================*/

.body_bg {
  background-color: $brand-dark;
  overflow-x: hidden;
}
.main_content {
  margin-left: 232px;
  overflow: hidden;
  padding: 96px 32px 32px;
  background-color: $brand-white;
  position: relative;
  @include break(tablet) {
    padding: 96px 20px 30px;
  }
  @include break(mobile) {
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    margin-bottom: 20px;
  }
}
.nav-tabs {
  background-color: $neutral_10;
  border-radius: 30px;
  padding: 8px;
  border: none;
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;

  .nav-link,
  .nav-item {
    background-color: $neutral_10;
    border: none;
    border-radius: 30px;
    color: $neutral_40;
    font-size: $fs-14;
    width: 100%;
    @include break(mobile) {
      font-size: 12px;
    }
  }
  .active {
    background-color: $brand-light-green !important;
    color: $brand-white !important;
  }
}

.text-smaller {
  @media (min-width: 991px) {
    background-color: rgba(15, 52, 67, 0.9);
  }
}

.navbar {
  padding: 30px 0;
  @include break(tablet) {
    background-color: $brand-white;
    padding: 10px 0;
  }

  .flexgrow {
    flex-grow: inherit;
  }

  .for_mobile {
    .navbar-nav {
      li {
        margin-top: 16px;
        @include break(tablet) {
          margin-right: 0px;
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          background-color: #0b2732;
          border-radius: 8px;
          padding: 12px;
          font-weight: 400;
          font-family: $fonfamily;

          .svg_icon {
            margin-right: 14px;
          }
        }
      }
    }
  }

  .navbar-nav {
    li {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px !important;
      }

      a {
        color: $brand-white !important;
        font-size: $fs-14;
        font-weight: 400;
        text-decoration: none;
        font-family: $fonfamily;
      }

      .br-left {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 20px;
          background-color: $brand-white;
          left: -6px;
          opacity: 0.2;
          @include break(sm-mobile) {
            display: none;
          }
        }
      }
    }

    @include break(tablet) {
      background-color: $brand-dark;
      height: 300px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -70px;
      }
    }
    @include break(mobile) {
      background-color: $brand-dark;
      height: 290px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -80px;
      }
    }
    @include break(sm-mobile) {
      background-color: $brand-dark;
      height: 280px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -80px;
      }
    }
    @media only screen and (min-width: 638px) and (max-width: 640px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }

    @media only screen and (min-width: 562px) and (max-width: 564px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }
    @media only screen and (min-width: 566px) and (max-width: 568px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }
    @media only screen and (min-width: 479px) and (max-width: 480px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;
      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }
  }
}

.banner_sec {
  padding: 200px 0 0 0;
  @include break(desktop) {
    padding: 110px 0 0 0;
  }
}

@include break(tablet) {
  .for_destop {
    display: none;
  }
}

.contact_us {
  padding: 175px 0 0 0;
  position: relative;

  &::before {
    content: '';
    background-image: $footer_bg;
    position: absolute;
    left: 0;
    width: 100%;
    height: 970px;
    background-repeat: no-repeat;
    bottom: 0;
  }

  .footer_bg_sec {
    position: relative;

    .app_ad {
      padding-top: 150px;
      @include break(mobile) {
        padding-top: 100px;
      }
    }

    .contact_icon {
      position: relative;

      &::after {
        content: '';
        background-image: $vector;
        position: absolute;
        width: 650px;
        height: 550px;
        left: -130px;
        top: -90px;
        @include break(tablet) {
          display: none;
        }
      }

      @include break(desktop) {
        img {
          width: 100%;
        }
      }
      @include break(tablet) {
        img {
          width: 50px;
        }
      }
      @include break(tablet) {
        text-align: center;
      }
    }
  }

  .contact_detail {
    @include break(tablet) {
      text-align: center;
    }

    h1 {
      color: $brand-white;
      font-size: $subheading1;
      font-weight: 600;
      font-family: $fonfamily;
      @include break(tablet) {
        font-size: $fs-24;
      }
    }

    p {
      color: $banner-gray;
      font-family: $fonfamily;
      font-weight: 400;
      font-size: $fs-24;
      @include break(tablet) {
        font-size: $fs-18;
      }
    }

    .cont_fields {
      margin-top: 20px;

      .name_fields {
        display: flex;
        width: 100%;
        @include break(tablet) {
          display: inline-block;
        }
      }

      .name_inputfield {
        width: 40%;
        margin-right: 10px;
        @include break(tablet) {
          text-align: left;
          width: 100%;
        }

        label {
          width: 100%;
          font-size: 12px;
          color: $fields-color;
          margin-bottom: 5px;
        }

        input {
          height: 48px;
          background: $bg-dark-blue;
          border-radius: 8px;
          width: 100%;
          border: none;
          color: $brand-gray;
          padding-left: 15px;

          &:focus {
            outline: none;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .msg_field {
        margin-top: 15px;

        textarea {
          background: $bg-dark-blue;
          border-radius: 8px;
          width: 100%;
          height: 264px;
          border: none;
          color: $brand-gray;
          padding-left: 15px;
          padding-top: 10px;

          &:focus {
            outline: none;
          }
        }
      }

      .submit_btn {
        margin-top: 16px;

        button {
          @include btn-action;
          @include break(tablet) {
            width: 100%;
          }
        }
      }
    }
  }
}

.pt-150 {
  padding-top: 150px !important;
}

.app_ad {
  .find_music {
    @include break(tablet) {
      text-align: center;
    }

    h1 {
      font-size: $heading_font;
      font-weight: 600;
      color: $brand-white;
      font-family: $fonfamily;
      @include break(tablet) {
        font-size: $fs-32;
      }
    }
  }

  .download_app {
    text-align: right;
    position: relative;
    @include break(tablet) {
      text-align: center;
      margin-top: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: $btnbg;
      width: 100%;
      height: 211px;
      right: -39px;
      top: -40px;
      z-index: 0;
      @include break(tablet) {
        display: none;
      }
    }

    p {
      font-size: $fs-24;
      color: $banner-gray;
      font-family: $fonfamily;
      @include break(mobile) {
        font-size: $fs-18;
      }
    }

    .app_icons {
      position: relative;
      display: flex;
      justify-content: end;

      a {
        margin-left: 10px;
      }

      @include break(tablet) {
        justify-content: center;
      }
    }
  }
}

.footer_sec {
  padding-top: 150px;
  position: relative;
  @include break(mobile) {
    padding-top: 50px;
  }

  .footer_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include break(tablet) {
      align-items: center;
      flex-direction: column;
    }

    .f_menu {
      @include break(mobile) {
        width: 100%;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        @include break(tablet) {
          margin-top: 15px;
        }
        @include break(mobile) {
          display: inline-block;
          width: 100%;
          text-align: center;
        }

        li {
          list-style: none;
          margin-right: 25px;
          @include break(mobile) {
            margin-right: 0px;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            font-weight: 600;
            font-size: $pera;
            color: $brand-white;
            font-family: $fonfamily;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer_copyright {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    @include break(tablet) {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    .footer_main {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: space-between;
      @include break(tablet) {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 37px;
      }
    }

    .privacy {
      a {
        color: $brand-white;
        font-size: $pera;
        font-family: $fonfamily;
        text-decoration: none;
        transition: 0.3s all;
        margin-right: 30px;
      }
    }

    .copyright {
      p {
        margin-bottom: 0;
        color: $brand-white;
        font-size: $pera;
        font-family: $fonfamily;
        margin-bottom: 0;
      }
    }

    .social_media {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        @include break(tablet) {
          justify-content: center;
        }

        li {
          list-style: none;
          margin-right: 50px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.choose_plan {
  background-color: $brand-white;
  transform: skew(360deg, 352deg);
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  padding: 15rem 0;
  margin-top: -110px;
  @include break(desktop) {
    padding: 15rem 0 100px 0;
  }

  .container {
    transform: skew(-360deg, -352deg);
  }

  .choose_plan_heading {
    text-align: center;

    h1 {
      margin-top: 40px;
      font-size: $subheading1;
      font-weight: 600;
      color: $brand-dark;
      margin-bottom: 20px;
      font-family: $fonfamily;
      @include break(tablet) {
        font-size: $fs-24;
      }
    }

    p {
      font-size: $subheading;
      font-family: $fonfamily;
      color: $brand-gray;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 50px;
      @include break(tablet) {
        font-size: $fs-18;
      }
    }
  }

  .plan_details {
    padding: 32px;
    height: auto;
    transition: 0.3s all;
    border-radius: 16px;
    @include break(tablet) {
      background-color: rgba(207, 214, 217, 0.2);
    }

    &:hover {
      background-color: rgba(207, 214, 217, 0.2);
    }

    h5 {
      font-weight: 600;
      font-size: $fs-16;
      font-family: $fonfamily;
      color: $brand-dark;
      margin: 0;
    }

    h6 {
      font-size: $fs-10;
      font-family: $fonfamily;
      font-weight: 600;
      color: $brand-gray;
      margin-top: 5px;
      margin-bottom: 0;
    }

    h1 {
      font-size: $fs-24;
      font-family: $fonfamily;
      font-weight: 600;
      color: $brand-dark;
      margin-top: 5px;
      margin-bottom: 0;
    }

    p {
      font-size: $pera;
      font-family: $fonfamily;
      font-weight: 400;
      color: $brand-gray;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .get_started {
      margin-top: 16px;

      button {
        @include btn-action;
      }
    }

    ul {
      margin-top: 15px;
      padding-left: 21px;
      margin-bottom: 0;

      li {
        list-style: none;
        font-size: $pera;
        font-family: $fonfamily;
        font-weight: 400;
        color: $brand-dark;
        margin-bottom: 10px;
        position: relative;

        &:after {
          background-image: $check;
          content: '';
          position: absolute;
          width: 13px;
          height: 12px;
          left: -23px;
          top: 4px;
        }
      }
    }
  }
}

@include break(tablet) {
  .pricing_for_web {
    display: none;
  }
}

/*====================
Toast Component Css
====================*/
.custom-popover {
  z-index: 1055 !important;
}
.toast-container {
  width: 100% !important;
  display: flex;
  padding: 8px 15px 8px 50px !important;

  .custom-toast {
    display: flex;
    align-items: center;
    &.error_msg_title {
      display: flex;
      align-items: center;
    }

    &.toast-title {
      margin-right: 10px;
    }

    &.toast-top-right {
      top: 12px;
      right: 43px !important;
    }

    .toast_cross_icon {
      margin-left: auto !important;
    }

    .toast-message {
      color: $text-black;
      font-size: $fs-14;
    }

    &.toast-success {
      background-image: url('../../../libs/theme/assets/images/check_icon.png') !important;
      background-color: $brand-white;
      display: flex;
      margin-top: 15px;
      border-radius: 8px;
      align-items: center;
    }

    &.toast-error {
      background-image: url('../../../libs/theme/assets/images/red_cross.png') !important;
      background-color: $brand-white;
      display: flex;
      margin-top: 15px;
      border-radius: 8px;
      align-items: center;
    }

    .toast-close-button {
      right: 2px !important;
      top: -3px !important;
    }
  }
}

/*====================
Custom Pagination
====================*/

.custom-pagination {
  pagination {
    display: flex;
    justify-content: center;
    .pagination {
      padding-top: 0;
      overflow-x: auto;
      border-radius: 5px;
      max-width: max-content;
      margin-left: auto;
      margin-bottom: 20px;
      margin-top: 15px;

      .page-item {
        color: rgba(0, 0, 0, 0.5) !important;
        font-weight: 500;
        transition: all 0.3s;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 0;
        margin: 0;
        border-radius: 0;

        .page-link {
          color: rgba(0, 0, 0, 0.5) !important ;
          border: none;
          background-color: transparent;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &.pagination-first {
          border-radius: 5px 0 0 5px;
        }

        &.pagination-last {
          border-radius: 0 5px 5px 0;
          border: 1px solid rgba(0, 0, 0, 0.1);

          &:hover {
            border-right: 1px solid $brand-light-green;
          }
        }

        &.active {
          color: $brand-white !important;
          border: 1px solid $brand-light-green;
          background: $brand-light-green;

          .page-link {
            color: $brand-white !important;
          }

          &:hover {
            background: $brand-light-green;
            color: $brand-white !important;
            border: 1px solid $brand-light-green;
          }
        }

        &:hover {
          background: rgba($brand-light-green, 0.1);
          color: $brand-light-green !important;
          border: 1px solid rgba($brand-light-green, 0.1);
          border-right: 0;
        }

        &.disabled {
          color: $bg-white-disable !important;
          cursor: no-drop;

          .page-link {
            color: #6c757d;
          }
        }
      }
    }
  }
}

.custom-table {
  .dataTables_wrapper {
    .dataTables_filter {
      position: relative;
      margin-bottom: 1.5rem;
      @media (max-width: 767px) {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
      }
      label {
        width: 100%;

        input {
          width: 100%;
          background-color: $bg-white-disable;
          border-radius: 16px;
          border: 1px solid $neutral_20;
          color: $text-black;
          padding: 0.375rem 0.75rem 0.375rem 35px;
          height: 48px;
          margin-left: 0;

          &::placeholder {
            color: $neutral_40;
          }

          &:focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
          }
        }
      }

      &:after {
        color: $neutral_40;
        position: absolute;
        font-family: 'Font Awesome 6 Free';
        font-weight: 600;
        font-size: $fs-14;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        transition: all 0.3s;
        content: '\f002';
      }
    }

    .dataTables_scroll {
      background-color: $bg-white-disable;
      padding: 14px 26px;
      border-radius: 16px;
      overflow: hidden;

      .dataTables_scrollHead {
        background-color: $brand-white;
        border: none;
        margin: 7px 0;
        border-radius: 16px;

        .sb-table {
          border: none !important;
          border-image: none;
          width: 100% !important;

          thead {
            td {
              border: none;
              padding-left: 12px;
              padding-right: 12px;

              p {
                width: fit-content;
                color: $neutral_80;
                font-size: $fs-14;
              }

              &.sorting {
                background-image: none !important;

                &:before,
                &:after {
                  display: none;
                }

                p {
                  &:before,
                  &:after {
                    color: $neutral_40;
                    position: absolute;
                    font-family: 'Font Awesome 6 Free';
                    font-weight: 900;
                    font-size: 9px;
                    right: -18px;
                    transform: translateY(-50%) rotateY(0);
                    transition: all 0.3s;
                  }

                  &:before {
                    content: '\f106';
                    top: 30%;
                  }

                  &:after {
                    content: '\f107';
                    top: 65%;
                  }
                }

                &.sorting_desc {
                  p {
                    &:before {
                      display: none;
                    }

                    &:after {
                      display: block;
                    }
                  }
                }

                &.sorting_asc {
                  p {
                    &:before {
                      display: block;
                    }

                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .dataTables_scrollBody {
        position: unset !important;
        border: 0;
        margin-top: -10px;

        .sb-table {
          border-collapse: separate;
          border-spacing: 0 15px;

          tbody {
            tr {
              td {
                border-radius: 16px 0 0 16px;
                white-space: unset;

                + td {
                  border-radius: 0;
                }

                &:last-child {
                  border-radius: 0 16px 16px 0;
                }

                &:only-child {
                  border-radius: 16px;
                  text-align: center;
                }

                background-color: $brand-white;
                border: 0;
                padding: 12px;

                .store-content-details {
                  display: flex;
                  align-items: center;
                  font-size: $fs-16;
                  word-break: break-word;

                  @include break(desktop) {
                    font-size: $fs-14;
                  }

                  &.transaction {
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  img {
                    max-width: 32px;
                    height: 32px;
                    width: 100%;
                    margin-right: 10px;
                    object-fit: cover;
                  }
                  .coin-icon {
                    max-width: 20px;
                    width: 100%;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }

      @include break(sm-mobile) {
        padding: 12px;
      }
    }
    
    .dataTables_paginate {
      display: flex;
      white-space: nowrap;
      padding-top: 0;
      overflow-x: auto;
      margin-top: 15px;
      border-radius: 5px;
      float: unset;
      max-width: max-content;
      margin-left: auto;

      .paginate_button {
        color: rgba(0, 0, 0, 0.5) !important;
        font-weight: 500;
        transition: all 0.3s;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 0;
        margin: 0;
        border-radius: 0;
        padding: 0.3rem 0.75rem;
        flex-shrink: 0;

        &.first {
          border-radius: 5px 0 0 5px;
        }

        &.last {
          border-radius: 0 5px 5px 0;
          border: 1px solid rgba(0, 0, 0, 0.1);

          &:hover {
            border-right: 1px solid #bfd0da;
          }
        }

        &.current {
          color: $brand-white !important;
          border: 1px solid $brand-primary;
          background: $brand_primary;

          &:hover {
            background: $brand_primary;
            color: $brand-white !important;
            border: 1px solid $brand-primary;
          }
        }

        &:hover {
          background: #bfd0da;
          color: $brand-primary !important;
          border: 1px solid #bfd0da;
          border-right: 0;
        }

        &.disabled {
          background: $bg-white-disable;
          color: #a4b6c0 !important;
          cursor: no-drop;
        }
      }

      .ellipsis {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 6px;
        padding-bottom: 7px;
      }
    }

    .dataTables_processing {
      z-index: 1;
      margin-top: 0;
    }
  }
}
.modal-card {
  .dataTables_scroll {
    border: 0 !important;
    p {
      color: black !important;
    }
  }
}
.back_arrow img {
  max-width: 18px;
  width: 100%;
}
.action-switch-btn {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  min-width: 48px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: $brand-light-green;
        border-color: $brand-light-green;

        &:before {
          transform: translateX(22px);
          background-color: $brand-white;
        }
      }
    }
  }

  .slider {
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-white;
    transition: 0.4s;
    border: 2px solid $brand-primary;

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 2px;
      background-color: $brand-primary;
      transition: 0.4s;
    }
  }
}

.custom-datepicker {
  box-shadow: none;
  background: transparent;

  .bs-datepicker-container {
    padding-top: 5px;

    .bs-calendar-container {
      .bs-media-container {
        .bs-datepicker-head {
          background-color: $brand-primary;
          border-radius: 16px 16px 0 0;

          button {
            &:not([disabled]):hover {
              background-color: $orange-switch;
              color: $brand-white;
            }
          }
        }

        .bs-datepicker-body {
          background-color: $brand-white;
          border-radius: 0 0 16px 16px;

          table {
            th {
              color: #bfd0da;
            }

            td {
              color: #173d42;

              &.is-other-month {
                color: $neutral_20;
              }

              &.is-highlighted {
                &:not(.disabled):not(.selected) {
                  span {
                    background-color: #f0b583;
                    color: $brand_white;
                  }
                }
              }

              span {
                &.is-highlighted {
                  &:not(.disabled):not(.selected) {
                    background-color: #f0b583;
                    color: $brand_white;
                  }
                }
              }

              &.selected {
                background-color: $orange-switch;

                span {
                  background-color: $orange-switch;
                }
              }

              span {
                &.selected {
                  background-color: $orange-switch;
                }
              }

              &.disabled {
                cursor: not-allowed;

                &:not(.is-other-month) {
                  span {
                    color: #a4b6c0;
                  }
                }
              }

              span {
                &.disabled {
                  cursor: not-allowed;

                  &:not(.is-other-month) {
                    color: #a4b6c0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.light-select {
  .creatable-select {
    .ng-select {
      background-color: $brand-white;
      border: 1px solid $neutral_20;
      border-radius: 16px;

      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          border-radius: 0 0 16px 16px;
          background: $brand-white;
          box-shadow: 0 32px 32px #0000001a;

          .ng-option {
            font-weight: 700;
            padding: 10px 15px;
            color: $text-black;
            transition: all 0.3s;

            &:hover {
              background: #fef6f0;
            }
            &.ng-option-selected {
              color: $orange-switch;
            }
          }
        }
      }

      .ng-select-container .ng-value-container .ng-input > input {
        color: $text-black;
      }

      &.ng-select-opened {
        background: $brand-white;
        border-color: $brand-white;
        border-radius: 16px 16px 0 0;
        box-shadow: 0 6px 32px #0000001a;
      }
    }
  }
}
.send-fan-modal {
  max-width: 600px !important;
  .modal-footer {
    margin: 0 16px 16px;
    gap: 6px;
    button {
      padding: 12px 30px;
      margin: 0;
    }
  }
}
.back_save,
.back_btn {
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 15px;
    color: $neutral_80 !important;
    img {
      max-width: 17px;
      width: 100%;
    }
  }
}
.close-icon {
  max-width: 20px;
  width: 100%;
  cursor: pointer;
}
.btn-close {
  opacity: 1;
}
.modal-body {
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    opacity: 1;
  }
}
.btn-verify {
  @include btn-action;
}
.btn-cancel {
  border: 1px solid $brand-color !important;
}
.dropdown-menu {
  .dropdown-item {
    display: flex;
    gap: 6px;
    li {
      display: flex;
      gap: 6px;
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
::placeholder {
  font-size: $fs-14;
}
input:focus{
  box-shadow: unset !important;
}
.plus-add-btn {
  display: none;
  @include break(mobile) {
    position: fixed;
    right: 15px;
    bottom: 70px;
    z-index: 5;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    background-color: $brand-primary;
    border: 0;
    i {
      font-size: 20px;
      color: $brand-white;
    }
  }
}
.send-tokens {
  .dataTables_wrapper {
    .dataTables_scroll {
      overflow-y: auto;
      max-height: 380px;
    }
  }
}